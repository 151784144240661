<template>
    <Breadcrumb page-title="Enhancement Request Form " page-section="Custom Forms" page-des="Please send your request related to product enhancement."> </Breadcrumb>
    <section class="content">
        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-body">
                        <div class="row">
                        <div class="col-md-8 form-horizontal">
                            <div class="form-group">
                                <label class="col-sm-8 control-label text-left"></label>
                            </div>
                            <input type="hidden" value="scott" class="form-control" disabled="" autocomplete="off">
                            <input type="hidden" value="scott@1t01service.com" class="form-control" disabled="" autocomplete="off">
                            <input type="hidden" value="styleguide" class="form-control" disabled="" autocomplete="off">
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Page To Update <i class="fa fa-question-circle text-info help-icon" uib-tooltip="Enter the URL or the name of the page that needs to be updated. If you need a new page, or a changes to a process, indicate that here." tooltip-placement="bottom"></i></label>
                                <div class="col-sm-6">
                                    <input type="text" value="styleguide" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Business Purpose <i class="fa fa-question-circle text-info help-icon" uib-tooltip="Tell us about the business need you're trying to solve. Please don't describe the change in terms of updates to a page. Instead, tell us as much about your business process as possible. We'll follow up with you for clarification if needed." tooltip-placement="bottom"></i></label>
                                <div class="col-sm-6">
                                    <textarea class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Problem with current page or process <i class="fa fa-question-circle text-info help-icon" uib-tooltip="Explain the difficulty you have with the current process." tooltip-placement="bottom"></i>
                                </label>
                                <div class="col-sm-6">
                                    <textarea class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Urgency <i class="fa fa-question-circle text-info help-icon" uib-tooltip-html="tooltipContent" tooltip-placement="bottom"></i></label>
                                <div class="col-sm-6">
                                    <select class="form-control">
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">Attachments <i class="fa fa-question-circle text-info help-icon" uib-tooltip="If possible, provide a page mockup that shows changes required." tooltip-placement="bottom"></i></label>
                                <div class="col-sm-6">
                                    <iservice-files-upload ng-init="uploads = []" files="uploads" class="ng-isolate-scope">
                                    <div class="form-group">
                                        <div class="col-md-12">
                                            <label class="drop-box">
                                              Drop files here or click to upload   
                                            </label>
                                            <div>(Maximum 10 MB upload at one time.)</div>
                                            <div class="imageuploadify">
                                                <!-- ngRepeat: file in files -->
                                            </div>
                                        </div>
                                    </div>
                                    </iservice-files-upload>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-4 control-label text-left">&nbsp;</label>
                                <div class="col-md-6">
                                    <button type="button" class="btn btn-primary margin-r-5">Submit Request</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

    </section>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
 // @ is an alias to /src
 import Breadcrumb from '../components/Breadcrumb.vue';
 import Toggle from '@vueform/toggle'
 export default {
   name: 'New Account',
   components: {
     Breadcrumb,
     Toggle,
   },
   data: function(){
       return{
        
       }
          
   },
   ready () {
     $(document).ready(function ($) {
     })
   },
    methods: {
     toggleSegmentCollapsedState(i) {
       this['segment'+i] = !this['segment'+i] 
     }
   }
 }
 </script>
 